/* You can add global styles to this file, and also import other style files */
// Import Exo 2
@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@font-face {
  font-family: "Exo 2 Regular";
  src: url("/assets/fonts/Exo2-Condensed/Exo 2 Regular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Exo 2 Regular", "Exo 2", sans-serif;
}

body::-webkit-scrollbar {
  display: none;
}
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

* {
  box-sizing: border-box;
}

.blinking {
  animation: blink 1s;
  animation-iteration-count: infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

// #region Fonts
@font-face {
  font-family: "Exo 2 Medium";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo 2 Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Exo 2 Medium Condensed";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-MediumCondensed.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Regular Condensed";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-RegularCondensed.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Regular Expanded";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-RegularExpanded.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Extra Light";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo 2 Extra Light.ttf")
    format("truetype");
}

@font-face {
  font-family: "Exo 2 Extra Light Condensed";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-ExtraLightCondensed.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Extra Light Expanded";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-ExtraLightExpanded.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Thin Condensed";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-ThinCondensed.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Thin Expanded";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-ThinExpanded.woff") format("woff");
}

@font-face {
  font-family: "Exo 2 Light Condensed";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-LightCondensed.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Light Expanded";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-LightExpanded.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Medium Expanded";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-MediumExpanded.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Semi Bold";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo 2 Semi Bold.ttf")
    format("truetype");
}

@font-face {
  font-family: "Exo 2 Semi Bold Condensed";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-SemiBoldCondensed.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Semi Bold Expanded";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-SemiBoldExpanded.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Bold Condensed";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-BoldCondensed.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Bold Expanded";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-BoldExpanded.woff") format("woff");
}

@font-face {
  font-family: "Exo 2 Extra Bold Condensed";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-ExtraBoldCondensed.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Extra Bold Expanded";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-ExtraBoldExpanded.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Black";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo 2 Black.ttf") format("truetype");
}

@font-face {
  font-family: "Exo 2 Black Condensed";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-BlackCondensed.woff")
    format("woff");
}

@font-face {
  font-family: "Exo 2 Black Expanded";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/Exo2-Condensed/Exo2-BlackExpanded.woff")
    format("woff");
}
// #endregion
